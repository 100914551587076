import React from "react";
import "./Input.scss";

type Props = {
  type: string | any;
  id: string | any;
  onChange: (e: React.FormEvent<HTMLInputElement>) => void;
};

const Input: React.FC<Props> = ({ type, id, onChange }) => {
  return (
    <input className="custom-input" onChange={onChange} type={type} id={id} />
  );
};

export default Input;
