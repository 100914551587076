import React from "react";
import IconCancel from "../../icons/IconCancel";
import IconCheckmark from "../../icons/IconCheckmark";
import IconDelete from "../../icons/IconDelete";
import "./TodoItem.scss";

type Props = TodoProps & {
  updateTodo: (todo: ITodo) => void;
  deleteTodo: (_id: string) => void;
};

const Todo: React.FC<Props> = ({ todo, updateTodo, deleteTodo }) => {
  const checkTodo: string = todo.status ? `line-through` : "";
  const statusButton: string = todo.status
    ? `Card--button__not-completed`
    : `Card--button__completed`;
  return (
    <div className="Card">
      <div className="Card--text">
        <h1 className={checkTodo}>{todo.name}</h1>
        <span className={checkTodo}>{todo.description}</span>
      </div>
      <div className="Card--button">
        <button onClick={() => updateTodo(todo)} className={statusButton}>
          {todo.status ? <IconCancel /> : <IconCheckmark />}
        </button>
        <button
          onClick={() => deleteTodo(todo._id)}
          className="Card--button__delete"
        >
          <IconDelete />
        </button>
      </div>
    </div>
  );
};

export default Todo;
