import React, { useState } from "react";
import IconAdd from "../../icons/IconAdd";
import Input from "../Input/Input";
import "./AddTodo.scss";

type Props = {
  saveTodo: (e: React.FormEvent, formData: ITodo | any) => void;
};

const AddTodo: React.FC<Props> = ({ saveTodo }) => {
  const [formData, setFormData] = useState<ITodo | {}>();

  const handleForm = (e: React.FormEvent<HTMLInputElement>): void => {
    setFormData({
      ...formData,
      [e.currentTarget.id]: e.currentTarget.value,
    });
  };

  return (
    <form
      className="Form"
      onSubmit={(e) => {
        saveTodo(e, formData);
        e.currentTarget.reset();
      }}
    >
      <div className="form-input-container">
        <div className="form-input">
          <label htmlFor="name">Name</label>
          <Input onChange={handleForm} type="text" id="name" />
        </div>
        <div className="form-input">
          <label htmlFor="description">Description</label>
          <Input onChange={handleForm} type="text" id="description" />
        </div>
      </div>
      <div className="form-button-container">
        <button disabled={formData === undefined ? true : false}>
          <IconAdd />
        </button>
      </div>
    </form>
  );
};

export default AddTodo;
